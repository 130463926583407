exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dl-thanks-js": () => import("./../../../src/pages/dl_thanks.js" /* webpackChunkName: "component---src-pages-dl-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-nazotoki-artmuseum-js": () => import("./../../../src/pages/nazotoki-artmuseum.js" /* webpackChunkName: "component---src-pages-nazotoki-artmuseum-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

